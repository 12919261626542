import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'state/store';

import { getMappedBusinessesPersons } from './utils';

export const getRoot = (state: RootState) => state.login;

export const getLoginData = createSelector([getRoot], ({ data }) => data);

export const getPhoneCountries = createSelector(
  [getRoot],
  ({ countries }) => countries,
);

export const getIsCodeSent = createSelector(
  [getRoot],
  ({ isCodeSent }) => isCodeSent,
);

export const getCurrentTab = createSelector(
  [getRoot],
  ({ currentTab }) => currentTab,
);

export const getIsLoading = createSelector(
  [getRoot],
  ({ isLoading }) => isLoading,
);

export const getSessionExpired = createSelector(
  [getRoot],
  ({ sessionExpired }) => sessionExpired,
);

export const getBusinesses = createSelector(
  [getRoot],
  ({ businesses }) => businesses,
);

export const getBusinessPersons = createSelector([getRoot], ({ businesses }) =>
  getMappedBusinessesPersons(businesses),
);

export const getShowBusinessList = createSelector(
  [getRoot],
  ({ showBusinessList }) => showBusinessList,
);
