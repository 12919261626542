import { createAsyncThunk, createAction } from '@reduxjs/toolkit';

import { fetchBusinessAdditionalData } from 'pages/dashboard/components/BusinessInformation/thunks';
import { setIsAdmin } from 'pages/Profile/profileSlice';
import { getPersonBusinesses } from 'services/persons';
import { getBusinesses } from 'pages/login/selectors';
import { getCommonDropdowns } from 'services/common';
import { getSubDomain } from 'utils/domainService';
import { FilterType } from 'entities/accounting';
import { RootState } from 'state/store';
import { config } from 'config';
import path from 'common/path';
import {
  fetchBankAccounts,
  fetchBankIntegrators,
} from 'pages/dashboard/components/BankAccounts/thunks';
import {
  fetchProfileByEmail,
  fetchPersonPermissions,
} from 'pages/Profile/thunks';

import { fetchCrossBorder } from './components/Transactional/thunks';
import { findBusinessPlan } from './components/BusinessPlan/thunks';
import { fetchFinancialData } from './components/Financial/thunks';
import { getParsedDropdowns, isBusinessRegistered } from './utils';
import { fetchBPSTrack } from './components/Configuration/thunks';
import { fetchOwners } from './components/Ownership/thunks';
import {
  fetchErpAccounts,
  fetchAllConnectors,
} from './components/AccountingSoftware/thunks';

export const clearDashboard = createAction('CLEAR_DASHBOARD');

export const fetchCommonDropdowns = createAsyncThunk(
  'dashboard/fetchCommonDropdowns',
  async () => {
    try {
      const result = await getCommonDropdowns();
      const dropdowns = getParsedDropdowns(result);

      return dropdowns;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const fetchDashboardInformation = createAsyncThunk(
  'dashboard/fetchDashboardInformation',
  async (email: string, thunkApi) => {
    try {
      const { dispatch, getState } = thunkApi;

      const action = await dispatch(fetchProfileByEmail(email));

      if (!isBusinessRegistered(action.payload)) {
        window.location.replace(path.profile);
        return [];
      }

      await dispatch(fetchPersonPermissions());

      dispatch(fetchCommonDropdowns());
      dispatch(fetchErpAccounts());
      dispatch(fetchAllConnectors(FilterType.erp));
      dispatch(fetchBankAccounts());
      dispatch(fetchBankIntegrators());
      dispatch(fetchOwners());
      dispatch(fetchCrossBorder());
      dispatch(findBusinessPlan());
      dispatch(fetchFinancialData());
      dispatch(fetchBPSTrack());

      let businesses = getBusinesses(getState() as RootState);

      if (!businesses.length) {
        businesses = await getPersonBusinesses(
          action.payload?.person?.personsId,
        );
      }

      const currentBusiness = businesses.find(
        (p) => p.businessesId === action.payload.business.businessesId,
      );

      if (
        currentBusiness &&
        currentBusiness.businessesTenantUrlSub &&
        getSubDomain() !== currentBusiness.businessesTenantUrlSub
      ) {
        window.location.href = `${config.PROTOCOL}${currentBusiness.businessesTenantUrlSub}/login`;
        return [];
      }

      const isAdmin =
        businesses.find(
          (p) => p.businessesId === action.payload.business.businessesId,
        )?.businessesAdmin || false;

      dispatch(setIsAdmin(isAdmin));

      await dispatch(fetchBusinessAdditionalData(action.payload));

      return businesses;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);
