import { useTranslation, Trans } from 'react-i18next';

import { InfoCircleSolidIcon } from 'components/icons';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Checkbox } from 'components/Inputs';
import Link from 'components/Link';
import path from 'common/path';

import { setPrivacyCheckbox, setTermsCheckbox } from '../profileSlice';
import { addTandCaccept, addTandCview } from '../thunks';
import { getCheckboxes } from '../selectors';
import { useState } from 'react';
import Loading from 'components/Loading';

enum LinkTypes {
  terms = 'terms',
  privacy = 'privacy',
}

const TermsAndConditionCheckBox = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation('profile');

  const { termsCheckbox, privacyCheckbox } = useAppSelector(getCheckboxes);

  const [isTermsLoading, setIsTermsLoading] = useState<boolean>(false);
  const [isPrivacyLoading, setIsPrivacyLoading] = useState<boolean>(false);

  const links = {
    terms: `${path.terms}/${i18n.language.toUpperCase()}`,
    privacy: `${path.privacy}/${i18n.language.toUpperCase()}`,
  };

  const handleReview = (type: LinkTypes) => {
    if (type === LinkTypes.terms && !termsCheckbox.show) {
      const version = `onboarding${links.terms}`;
      dispatch(setTermsCheckbox({ show: true, version }));
      dispatch(addTandCview(version));
    }

    if (type === LinkTypes.privacy && !privacyCheckbox.show) {
      const version = `onboarding${links.privacy}`;
      dispatch(setPrivacyCheckbox({ show: true, version }));
      dispatch(addTandCview(version));
    }
  };

  const handleTerms = async (value: boolean) => {
    if (value && !termsCheckbox.id) {
      setIsTermsLoading(true);

      const result = await dispatch(addTandCaccept(`onboarding${links.terms}`));

      if (result.payload) {
        await dispatch(setTermsCheckbox({ id: result.payload }));
      }

      setIsTermsLoading(false);
    }

    dispatch(setTermsCheckbox({ checked: value }));
  };

  const handlePrivacy = async (value: boolean) => {
    if (value && !privacyCheckbox.id) {
      setIsPrivacyLoading(true);

      const result = await dispatch(
        addTandCaccept(`onboarding${links.privacy}`),
      );

      if (result.payload) {
        await dispatch(setPrivacyCheckbox({ id: result.payload }));
      }

      setIsPrivacyLoading(false);
    }

    dispatch(setPrivacyCheckbox({ checked: value }));
  };

  return (
    <div className="py-4">
      <div className="flex items-center pb-3">
        <InfoCircleSolidIcon className="mr-1 mb-0.5 text-gray-400 w-4 h-4" />
        <p className="text-xs text-gray-500">{t('reviewInfoMessage')}</p>
      </div>
      <div className="w-full max-w-[500px]">
        <label className={`${termsCheckbox.show && 'cursor-pointer'}`}>
          <div className="flex items-center justify-between p-[14px] border border-gray-200 rounded-t-lg relative">
            <Loading loading={isTermsLoading} transparent />
            <p className="text-xs font-medium pr-9">
              <Trans ns="profile" i18nKey="termsOfUse">
                text
                <Link
                  href={links.terms}
                  target="_blank"
                  className="font-medium"
                  rel="noreferrer"
                  onClick={() => handleReview(LinkTypes.terms)}
                >
                  link
                </Link>
                text
              </Trans>
            </p>
            {termsCheckbox.show ? (
              <Checkbox
                onChange={handleTerms}
                checked={termsCheckbox.checked}
                disabled={false}
              />
            ) : (
              <Link
                href={links.terms}
                target="_blank"
                className="font-semibold text-xs"
                rel="noreferrer"
                onClick={() => handleReview(LinkTypes.terms)}
              >
                {t('review')}
              </Link>
            )}
          </div>
        </label>
        <label className={`${privacyCheckbox.show && 'cursor-pointer'}`}>
          <div className="flex items-center justify-between p-[14px] border border-gray-200 border-t-0 rounded-b-lg relative">
            <Loading loading={isPrivacyLoading} transparent />
            <p className="text-xs font-medium pr-9">
              <Trans ns="profile" i18nKey="privacyPolicy">
                text
                <Link
                  href={links.privacy}
                  target="_blank"
                  className="font-medium"
                  rel="noreferrer"
                  onClick={() => handleReview(LinkTypes.privacy)}
                >
                  link
                </Link>
                text
              </Trans>
            </p>
            {privacyCheckbox.show ? (
              <Checkbox
                onChange={handlePrivacy}
                checked={privacyCheckbox.checked}
                disabled={false}
              />
            ) : (
              <Link
                href={links.privacy}
                target="_blank"
                className="font-semibold text-xs"
                rel="noreferrer"
                onClick={() => handleReview(LinkTypes.privacy)}
              >
                {t('review')}
              </Link>
            )}
          </div>
        </label>
      </div>
    </div>
  );
};

export default TermsAndConditionCheckBox;
